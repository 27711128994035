import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { graphql } from 'gatsby'
import ReactFullpage from '@fullpage/react-fullpage'

import Layout from '../components/layout'
import ProjectContainer from '../components/project/container'
import ProjectCursor from '../components/project/cursor'

const ShowreelTemplate = inject(`projectStore`, `navStore`)(
  observer(
    class Showreel extends React.Component {
      componentWillMount() {
        const { projectStore, data } = this.props
        projectStore.init({
          projects: [data.contentfulProject],
          path: 'showreels'
        })
      }

      componentDidMount() {
        this.deviceCheck()
      }

      deviceCheck() {
        const { projectStore } = this.props
        if (process.browser && 'ontouchstart' in document.documentElement) {
          projectStore.setTouch(true)
        }
      }

      render() {
        const { projectStore, navStore } = this.props
        const { open: navOpen, splashOpen } = navStore
        const {
          project,
          audible,
          videoMode,
          videoButtonHover,
          infoButtonHover,
          infoOpen,
          loading,
          isTouch
        } = projectStore

        return (
          <Layout splashOpen={splashOpen}>
            <ReactFullpage
              licenseKey={'558CE3FE-BEC149CA-8863BA1E-2C015323'}
              verticalCentered={false}
              normalScrollElements={'header'}
              render={({ state, fullpageApi }) => (
                <ProjectContainer
                  key={`project-showreel`}
                  project={project}
                  splashOpen={splashOpen}
                  navOpen={navOpen}
                  isTouch={isTouch}
                />
              )}
            />
            {!isTouch && !splashOpen ? (
              <ProjectCursor
                audible={audible}
                videoMode={videoMode}
                videoButtonHover={videoButtonHover}
                infoButtonHover={infoButtonHover}
                infoOpen={infoOpen}
                loading={loading}
              />
            ) : null}
          </Layout>
        )
      }
    }
  )
)

ShowreelTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ShowreelTemplate

export const pageQuery = graphql`
  query showreelQuery($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      id
      title
      slug
      info {
        childMarkdownRemark {
          html
        }
      }
      credits {
        childMarkdownRemark {
          html
        }
      }
      client {
        id
        name
        slug
      }
      videos {
        id
        title
        vimeoUrl
        poster {
          id
          title
          fluid(maxHeight: 720, quality: 51) {
            sizes
            src
            srcSet
          }
        }
      }
    }
  }
`
